var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            [
              _c("gradient-select", {
                key: _vm.name,
                ref: "selector",
                attrs: {
                  "selection-color": _vm.nodeColor,
                  "current-focus": _vm.currentFocus,
                  gradient: _vm.currentGradient,
                },
                on: {
                  "selection-change": _vm.setPickerFocus,
                  "gradient-change": _vm.onGradientChange,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "ma-auto my-0 py-0", attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                ref: "fild",
                staticClass: "centered",
                attrs: {
                  disabled:
                    _vm.currentFocusId === null ||
                    _vm.currentFocusId === undefined ||
                    _vm.wrongInput,
                  value: _vm.getFocusedPosition,
                  min: 0,
                  max: 100,
                  rules: [
                    (v) => {
                      return v < 101 && v > -1
                    },
                  ],
                  type: "number",
                },
                on: { input: _vm.handleUserBS },
              }),
            ],
            1
          ),
          _c("v-color-picker", {
            staticClass: "mx-auto",
            attrs: { canvasHeight: _vm.canvasHeight, width: _vm.width },
            model: {
              value: _vm.pickColor,
              callback: function ($$v) {
                _vm.pickColor = $$v
              },
              expression: "pickColor",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }