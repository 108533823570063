var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentScheme &&
    _vm.currentScheme.borderColors &&
    _vm.currentScheme.borderColors.length > 0 &&
    _vm.schemeName != "none"
    ? _c(
        "v-row",
        { staticClass: "d-flex flex-wrap mt-n1" },
        [
          _c(
            "v-col",
            { staticClass: "mt-0 ml-md-2 pt-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-0 px-2",
                  staticStyle: { top: "64px", "z-index": "2" },
                  style: {
                    position: _vm.isMobile ? "unset" : "sticky",
                    borderTop: _vm.$route.params.jackpot_group_id
                      ? "unset"
                      : "10px solid var(--v-background-base)",
                  },
                  attrs: { flat: "" },
                },
                [
                  !_vm.$route.params.jackpot_group_id
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c("v-card-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.currentScheme.visible_name ??
                                    _vm.schemeName
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm.isMobile
                    ? _c(
                        "div",
                        [
                          _vm.currentScheme && _vm.currentScheme.borderColors
                            ? _c("LevelPreview", {
                                key: _vm.counter1,
                                attrs: {
                                  previewWidth: "100vw",
                                  previewHeight: "7vh",
                                  previewBorderWidth: "3px",
                                  iconUrl: _vm.currentScheme.iconUrl,
                                  iconTint: _vm.currentScheme.iconTint,
                                  backgroundColors:
                                    _vm.currentScheme.backgroundColors,
                                  backgroundColorStops:
                                    _vm.currentScheme.backgroundColorStops,
                                  textColors: _vm.currentScheme.textColors,
                                  textColorStops:
                                    _vm.currentScheme.textColorStops,
                                  borderColors: _vm.currentScheme.borderColors,
                                  borderColorStops:
                                    _vm.currentScheme.borderColorStops,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-form",
                    { ref: "textForm" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "justify-center align-center w-100" },
                        [
                          !_vm.isMobile
                            ? _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _vm.currentScheme &&
                                  _vm.currentScheme.borderColors
                                    ? _c("LevelPreview", {
                                        key: _vm.counter1,
                                        attrs: {
                                          previewWidth: "20vw",
                                          previewHeight: "7vh",
                                          previewBorderWidth: "3px",
                                          iconUrl: _vm.currentScheme.iconUrl,
                                          iconTint: _vm.currentScheme.iconTint,
                                          backgroundColors:
                                            _vm.currentScheme.backgroundColors,
                                          backgroundColorStops:
                                            _vm.currentScheme
                                              .backgroundColorStops,
                                          textColors:
                                            _vm.currentScheme.textColors,
                                          textColorStops:
                                            _vm.currentScheme.textColorStops,
                                          borderColors:
                                            _vm.currentScheme.borderColors,
                                          borderColorStops:
                                            _vm.currentScheme.borderColorStops,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", sm: "6" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  label: "Visible Name",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.minMaxCharacters,
                                  ],
                                },
                                on: {
                                  input: _vm.onVisibleNameChange,
                                  change: _vm.onVisibleNameChange,
                                },
                                model: {
                                  value: _vm.currentScheme.visible_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentScheme,
                                      "visible_name",
                                      $$v
                                    )
                                  },
                                  expression: "currentScheme.visible_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", sm: "6" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  label: "Bonus Level Icon Url",
                                  rules: [_vm.rules.required],
                                },
                                on: {
                                  input: _vm.onIconUrlChange,
                                  change: _vm.onIconUrlChange,
                                },
                                model: {
                                  value: _vm.currentScheme.iconUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentScheme, "iconUrl", $$v)
                                  },
                                  expression: "currentScheme.iconUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-2 mt-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "justify-center align-center text-center pa-2",
                      attrs: { cols: "12", sm: "6", md: "4" },
                    },
                    [
                      _vm._v(" Icon Tint: "),
                      _c("v-color-picker", {
                        key: `pick1-${_vm.pickerSize}`,
                        staticClass: "mx-auto",
                        attrs: {
                          mode: "hexa",
                          "hide-mode-switch": "",
                          "canvas-height": "250",
                          width: _vm.pickerSize,
                        },
                        on: { "update:color": _vm.onIconTintChange },
                        model: {
                          value: _vm.currentScheme.iconTint,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentScheme, "iconTint", $$v)
                          },
                          expression: "currentScheme.iconTint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      ref: "column",
                      staticClass: "justify-center text-center pa-2",
                      attrs: { cols: "12", sm: "6", md: "4" },
                    },
                    [
                      _vm._v(" Animation Tint: "),
                      _c("v-color-picker", {
                        key: `pick2-${_vm.pickerSize}`,
                        staticClass: "mx-auto",
                        attrs: {
                          mode: "hexa",
                          "hide-mode-switch": "",
                          width: _vm.pickerSize,
                          "canvas-height": "250",
                        },
                        on: { "update:color": _vm.onAnimationTintChange },
                        model: {
                          value: _vm.currentScheme.animationTint,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentScheme, "animationTint", $$v)
                          },
                          expression: "currentScheme.animationTint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-center text-center pa-2",
                      attrs: { cols: "12", sm: "6", md: "4" },
                    },
                    [
                      _vm._v(" Text Stroke: "),
                      _c("v-color-picker", {
                        key: `pick3-${_vm.pickerSize}`,
                        staticClass: "mx-auto",
                        attrs: {
                          mode: "hexa",
                          "hide-mode-switch": "",
                          width: _vm.pickerSize,
                          height: _vm.pickerSize,
                          "canvas-height": "250",
                        },
                        on: { "update:color": _vm.onTextStrokeChange },
                        model: {
                          value: _vm.currentScheme.textStroke,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentScheme, "textStroke", $$v)
                          },
                          expression: "currentScheme.textStroke",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "justify-center align-center text-center pa-2",
                      attrs: { cols: "12", sm: "6", md: "4" },
                    },
                    [
                      _vm._v(" Border Gradient: "),
                      _c("gradient-picker", {
                        key: `${_vm.counter2}-${_vm.pickerSize}`,
                        staticClass: "ma-0 pa-0 mx-auto",
                        attrs: {
                          name: "border",
                          gradientToEdit: _vm.borderGradient,
                          "canvas-height": "250",
                          width: _vm.pickerSize,
                        },
                        on: { onGradientSubmit: _vm.onBorderChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "justify-center align-center text-center pa-2",
                      attrs: { cols: "12", sm: "6", md: "4" },
                    },
                    [
                      _vm._v(" Background Gradient: "),
                      _c("gradient-picker", {
                        key: `${_vm.counter3}-${_vm.pickerSize}`,
                        staticClass: "ma-0 pa-0 mx-auto",
                        attrs: {
                          name: "background",
                          gradientToEdit: _vm.backgroundGradient,
                          "canvas-height": "250",
                          width: _vm.pickerSize,
                        },
                        on: { onGradientSubmit: _vm.onBackgroundChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "justify-center align-center text-center pa-2",
                      attrs: { cols: "12", sm: "6", md: "4" },
                    },
                    [
                      _vm._v(" Text Gradient: "),
                      _c("gradient-picker", {
                        key: `${_vm.counter4}-${_vm.pickerSize}`,
                        staticClass: "ma-0 pa-0 mx-auto",
                        attrs: {
                          name: "text",
                          gradientToEdit: _vm.textGradient,
                          "canvas-height": "250",
                          width: _vm.pickerSize,
                        },
                        on: { onGradientSubmit: _vm.onTextColorChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.schemeName == "none" ||
      _vm.schemeName == undefined ||
      _vm.schemeName == ""
    ? _c("div", [_vm._v(" PLEASE SELECT LEVEL FIRST ")])
    : _c("v-row", { staticClass: "justify-center align-center" }, [
        _c(
          "div",
          [
            _vm._v(" There is no scheme for this level on this theme. "),
            _c("br"),
            _vm._v(" Create scheme for this level. "),
            _c("br"),
            _c(
              "v-btn",
              {
                attrs: {
                  elevation: "1",
                  fab: "",
                  small: "",
                  color: "primary",
                  link: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.fillScheme(_vm.schemeName)
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-plus")])],
              1
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }