var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "slider",
      staticClass: "gradient-picker",
      class: `${_vm.classPrefix}-gradient-picker`,
      style: { background: _vm.pickerBackground },
      on: {
        mousedown: function ($event) {
          $event.preventDefault()
          return _vm.addAtClick.apply(null, arguments)
        },
        touchstart: function ($event) {
          $event.preventDefault()
          return _vm.addAtClick.apply(null, arguments)
        },
      },
    },
    _vm._l(_vm.data, function (val, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "handle-container",
          class: [`${_vm.classPrefix}-handle-container`, _vm.containerClass(i)],
          style: { left: `${_vm.percentage(val.position)}%` },
          on: {
            mousedown: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.select(i)
            },
            touchstart: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.select(i)
            },
            mouseover: function ($event) {
              return _vm.hover(i)
            },
            mouseleave: function ($event) {
              return _vm.unhover()
            },
          },
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data[i].position >= 0,
                expression: "data[i].position >= 0",
              },
            ],
            staticClass: "handle",
            class: [`${_vm.classPrefix}-handle`, _vm.knobClass(i)],
            style: { backgroundColor: _vm.data[i].color },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }