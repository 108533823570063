<template>
	<v-row
		v-if="
			currentScheme &&
			currentScheme.borderColors &&
			currentScheme.borderColors.length > 0 &&
			schemeName != 'none'
		"
		class="d-flex flex-wrap mt-n1">
		<v-col
			cols="12"
			class="mt-0 ml-md-2 pt-0">
			<v-card
				flat
				style="top: 64px; z-index: 2"
				:style="{
					position: isMobile ? 'unset' : 'sticky',
					borderTop: $route.params.jackpot_group_id
						? 'unset'
						: '10px solid var(--v-background-base)',
				}"
				class="ma-0 px-2">
				<div
					v-if="!$route.params.jackpot_group_id"
					class="d-flex justify-center">
					<v-card-title>
						{{ currentScheme.visible_name ?? schemeName }}
					</v-card-title>
				</div>
				<div v-else-if="isMobile">
					<LevelPreview
						:key="counter1"
						v-if="currentScheme && currentScheme.borderColors"
						previewWidth="100vw"
						previewHeight="7vh"
						previewBorderWidth="3px"
						:iconUrl="currentScheme.iconUrl"
						:iconTint="currentScheme.iconTint"
						:backgroundColors="currentScheme.backgroundColors"
						:backgroundColorStops="currentScheme.backgroundColorStops"
						:textColors="currentScheme.textColors"
						:textColorStops="currentScheme.textColorStops"
						:borderColors="currentScheme.borderColors"
						:borderColorStops="currentScheme.borderColorStops"></LevelPreview>
				</div>
				<v-form ref="textForm">
					<v-row class="justify-center align-center w-100">
						<v-col
							v-if="!isMobile"
							cols="4">
							<LevelPreview
								:key="counter1"
								v-if="currentScheme && currentScheme.borderColors"
								previewWidth="20vw"
								previewHeight="7vh"
								previewBorderWidth="3px"
								:iconUrl="currentScheme.iconUrl"
								:iconTint="currentScheme.iconTint"
								:backgroundColors="currentScheme.backgroundColors"
								:backgroundColorStops="currentScheme.backgroundColorStops"
								:textColors="currentScheme.textColors"
								:textColorStops="currentScheme.textColorStops"
								:borderColors="currentScheme.borderColors"
								:borderColorStops="
									currentScheme.borderColorStops
								"></LevelPreview>
							<!-- :image_name="schemeName" -->
							<!-- :visible_name="currentScheme.visible_name" -->
						</v-col>
						<v-col
							cols="12"
							md="4"
							sm="6">
							<v-text-field
								class="mt-4"
								label="Visible Name"
								v-model="currentScheme.visible_name"
								:rules="[rules.required, rules.minMaxCharacters]"
								v-on:input="onVisibleNameChange"
								v-on:change="onVisibleNameChange" />
						</v-col>
						<v-col
							cols="12"
							md="4"
							sm="6">
							<v-text-field
								class="mt-4"
								label="Bonus Level Icon Url"
								:rules="[rules.required]"
								v-model="currentScheme.iconUrl"
								v-on:input="onIconUrlChange"
								v-on:change="onIconUrlChange" />
						</v-col>
					</v-row>
				</v-form>
			</v-card>
			<v-row class="pa-2 mt-2">
				<v-col
					cols="12"
					sm="6"
					md="4"
					class="justify-center align-center text-center pa-2">
					Icon Tint:
					<v-color-picker
						mode="hexa"
						hide-mode-switch
						v-model="currentScheme.iconTint"
						v-on:update:color="onIconTintChange"
						:key="`pick1-${pickerSize}`"
						canvas-height="250"
						:width="pickerSize"
						class="mx-auto"></v-color-picker>
				</v-col>

				<v-col
					ref="column"
					cols="12"
					sm="6"
					md="4"
					class="justify-center text-center pa-2">
					Animation Tint:
					<v-color-picker
						mode="hexa"
						hide-mode-switch
						v-model="currentScheme.animationTint"
						v-on:update:color="onAnimationTintChange"
						:key="`pick2-${pickerSize}`"
						:width="pickerSize"
						canvas-height="250"
						class="mx-auto"></v-color-picker>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="4"
					class="justify-center text-center pa-2">
					Text Stroke:
					<v-color-picker
						mode="hexa"
						hide-mode-switch
						v-model="currentScheme.textStroke"
						v-on:update:color="onTextStrokeChange"
						:key="`pick3-${pickerSize}`"
						:width="pickerSize"
						:height="pickerSize"
						canvas-height="250"
						class="mx-auto"></v-color-picker>
				</v-col>
			</v-row>

			<v-row>
				<v-col
					cols="12"
					sm="6"
					md="4"
					class="justify-center align-center text-center pa-2">
					Border Gradient:
					<gradient-picker
						class="ma-0 pa-0 mx-auto"
						:key="`${counter2}-${pickerSize}`"
						name="border"
						:gradientToEdit="borderGradient"
						@onGradientSubmit="onBorderChange"
						canvas-height="250"
						:width="pickerSize"></gradient-picker>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="4"
					class="justify-center align-center text-center pa-2">
					Background Gradient:
					<gradient-picker
						class="ma-0 pa-0 mx-auto"
						:key="`${counter3}-${pickerSize}`"
						name="background"
						:gradientToEdit="backgroundGradient"
						@onGradientSubmit="onBackgroundChange"
						canvas-height="250"
						:width="pickerSize"></gradient-picker>
				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="4"
					class="justify-center align-center text-center pa-2">
					Text Gradient:
					<gradient-picker
						class="ma-0 pa-0 mx-auto"
						:key="`${counter4}-${pickerSize}`"
						name="text"
						:gradientToEdit="textGradient"
						@onGradientSubmit="onTextColorChange"
						canvas-height="250"
						:width="pickerSize"></gradient-picker>
				</v-col>
			</v-row>
		</v-col>
	</v-row>

	<div
		v-else-if="
			schemeName == 'none' || schemeName == undefined || schemeName == ''
		">
		PLEASE SELECT LEVEL FIRST
	</div>

	<v-row
		v-else
		class="justify-center align-center">
		<div>
			There is no scheme for this level on this theme.
			<br />
			Create scheme for this level.
			<br />

			<v-btn
				elevation="1"
				fab
				small
				color="primary"
				link
				@click="fillScheme(schemeName)">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</div>
	</v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { rules as defaultRules } from "../../../plugins/rules.ts";
import GradientPicker from "../../shared/GradientPicker.vue";
import LevelPreview from "../../shared/LevelPreview.vue";
import { isMobile } from "../../../constants/helpers";

export default {
	components: {
		LevelPreview,
		GradientPicker,
	},

	props: {
		schemeToEdit: {
			type: Object,
			default: function () {
				return {
					animationTint: "",
					backgroundColorStops: [],
					backgroundColors: [],
					borderColorStops: [],
					borderColors: [],
					iconUrl: "",
					textColorStops: [],
					textColors: [],
					textStroke: "",
					iconTint: "",
					visible_name: "",
				};
			},
		},
		schemeName: String,
	},

	data() {
		return {
			pickerSize: 450,
			focusId: null,
			borderGradient: {
				type: "linear",
				degree: 180,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
			borderPointsLength: 0,

			backgroundGradient: {
				type: "linear",
				degree: 180,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
			backgroundPointsLength: 0,

			textGradient: {
				type: "linear",
				degree: 180,
				points: [
					{
						left: 0,
						red: 0,
						green: 0,
						blue: 0,
						alpha: 1,
					},
					{
						left: 100,
						red: 255,
						green: 0,
						blue: 0,
						alpha: 1,
					},
				],
			},
			textPointsLength: 0,
			colorPickerWidth: "250px",

			selectedTheme: {},
			formText: {
				outerDarkColTxt: "Border Dark Color",
				outerHighlightColTxt: "Border Highlight Color",
				innerDarkColTxt: "Background Dark Color",
				innerHighlightColTxt: "Background Highlight Color",
			},
			origThemeJsonString: "",
			routeGroupID: -1,
			currentScheme: {
				// none: {
				borderColors: [],
				borderColorStops: [],
				backgroundColors: [],
				backgroundColorStops: [],
				textColors: [],
				textColorStops: [],
				textStroke: "",
				animationTint: "",
				iconTint: "",
				iconUrl: "",
				visible_name: "",
				// }
			},
			groupsListWithTheme: [],
			counter1: 0, // level preview force update
			counter2: 2, // border picker force update
			counter3: 4, // background picker force update
			counter4: 6, // text picker force update

			rules: {
				...defaultRules,
				greaterThanContribBetMin: (v) =>
					v >= Number(this.contribBetMin) ||
					"Must be greater than Contrib Bet Min",
				betweenMinValueMaxValue: (v) =>
					(v >= Number(this.minValue) && v <= Number(this.maxValue)) ||
					"Must be between Min value and Max value",
				betweenMinBonusMaxValue: (v) =>
					(v >= Number(this.minBonus) && v <= Number(this.maxValue)) ||
					"Must be between Min Bonus and Max value",
				validateVariance: (v) =>
					(v > 0 && v <= Number(this.varianceThreshold)) ||
					"Must be between 0 and " + this.varianceThreshold,
				greaterThanMinValue: (v) =>
					v >= Number(this.minValue) || "Must be greater or equal to Min Value",
				sharesAddUp: (v) =>
					this.sharesSum == 100 || "Sum of the shares must be 100%",
			},
		};
	},

	computed: {
		isMobile,
		...mapGetters([
			"getSpecificTheme",
			"getDefaultScheme",
			"getGroupThemeSetting",
			"getFilteredThemesList",
			"getFilteredLevelList",
			"getFilteredJackpotGroupList",
			"getThemesList",
		]),
	},

	methods: {
		changeShit() {
			const res = (this.$refs.column?.offsetWidth ?? 450) - 30;
			this.pickerSize = `${res}`;
		},
		...mapActions([
			"setBreadCrumbs",
			"createTheme",
			"loadGroupThemeSetting",
			"loadFilteredThemesList",
			"loadFilteredLevelList",
			"loadFilteredJackpotGroupList",
			"loadSpecificTheme",
			"removeGroupTheme",
			"setGroupTheme",
			"loadThemesList",
		]),

		forcePreviewUpdate() {
			this.counter1 = ++this.counter1 % 2;
		},

		forcePickersUpdate() {
			this.counter2 = (++this.counter2 % 2) + 2;
			this.counter3 = (++this.counter3 % 2) + 4;
			this.counter4 = (++this.counter4 % 2) + 6;
		},

		rgbToHex(c) {
			var hex = c.toString(16);
			return hex.length == 1 ? "0" + hex : hex;
		},

		hexToRgb(hex) {
			return hex
				.replace(
					/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
					(m, r, g, b) => "#" + r + r + g + g + b + b
				)
				.substring(1)
				.match(/.{2}/g)
				.map((x) => parseInt(x, 16));
		},

		convertColors(colors, stops) {
			let tmpColorArr = [];
			const arrayLength = colors.length;
			for (let i = 0; i < arrayLength; i++) {
				let col = colors[i];
				let rgbV = this.hexToRgb(col);
				tmpColorArr.push({
					left: stops[i],
					red: rgbV[0],
					green: rgbV[1],
					blue: rgbV[2],
					alpha: 1,
				});
			}
			return tmpColorArr;
		},

		fillColorPoints() {
			if (this.currentScheme.borderColors) {
				this.borderGradient = {
					borderColors: this.currentScheme.borderColors,
					borderColorStops: this.currentScheme.borderColorStops,
				};
				this.backgroundGradient = {
					backgroundColors: this.currentScheme.backgroundColors,
					backgroundColorStops: this.currentScheme.backgroundColorStops,
				};
				this.textGradient = {
					textColors: this.currentScheme.textColors,
					textColorStops: this.currentScheme.textColorStops,
				};
				this.forcePickersUpdate();
			}
		},

		onBorderChange(attrs, focusId) {
			this.currentScheme["borderColors"] = attrs.borderColors;
			this.currentScheme["borderColorStops"] = attrs.borderColorStops;
			this.forcePreviewUpdate();
			this.emitData("border");
		},

		onBackgroundChange(attrs) {
			this.currentScheme["backgroundColors"] = attrs.backgroundColors;
			this.currentScheme["backgroundColorStops"] = attrs.backgroundColorStops;
			this.forcePreviewUpdate();
			this.emitData("background");
		},

		onTextColorChange(attrs) {
			this.currentScheme["textColors"] = attrs.textColors;
			this.currentScheme["textColorStops"] = attrs.textColorStops;
			this.forcePreviewUpdate();
			this.emitData("text");
		},

		emitData(text) {
			this.$emit(text + "Change", [
				this.currentScheme[text + "Colors"],
				this.currentScheme[text + "ColorStops"],
			]);
		},

		onIconTintChange(c) {
			this.$emit("iconTintChange", c);
		},

		onAnimationTintChange(c) {
			this.$emit("animationTintChange", c);
		},

		onTextStrokeChange(c) {
			this.$emit("textStrokeChange", c);
		},

		onVisibleNameChange(c) {
			this.$emit("visibleNameChange", c); //this.currentScheme.visible_name)
		},

		onIconUrlChange() {
			this.$emit("iconUrlChange", this.currentScheme.iconUrl);
		},

		async getGroupsWithTheme(themeName) {
			this.groupsListWithTheme = [];
			if (themeName) {
				await this.loadFfilteredJackpotGroupList({
					bodyPayload: { theme_name: { eq: themeName } },
					queryParams: { per_page: 100 },
				});
				this.getFilteredJackpotGroupList.data.forEach((element) => {
					this.groupsListWithTheme.push({
						id: element.id,
						name: element.name,
					});
				});
			}
		},

		fillScheme(levelName) {
			this.currentScheme = this.getDefaultScheme[levelName]
				? JSON.parse(JSON.stringify(this.getDefaultScheme[levelName]))
				: JSON.parse(JSON.stringify(this.getDefaultScheme.blankScheme));
			this.fillColorPoints();
			this.emitData("border");
			this.emitData("background");
			this.emitData("text");
			this.onVisibleNameChange(this.getDefaultScheme[levelName].visible_name);
			this.onIconUrlChange();
		},

		compareThemes(themeCopy, themeOrig) {
			for (let key in themeCopy) {
				if (
					typeof themeOrig[key] != "number" &&
					!Object.keys(themeOrig).includes(key)
				) {
					console.warn(
						" key is missing in original theme ",
						key,
						"\n original theme ",
						themeOrig[key],
						"\n copy theme",
						themeCopy[key]
					);
					return false;
				}

				if (typeof themeCopy[key] == "object") {
					if (!this.compareThemes(themeCopy[key], themeOrig[key])) {
						return false;
					}
				} else if (
					typeof themeOrig[key] == "string" &&
					themeOrig[key][0] == "#" &&
					themeOrig[key].toUpperCase() !== themeCopy[key].toUpperCase()
				) {
					console.warn(
						"it failed on > # ",
						themeOrig[key].toUpperCase(),
						" <> ",
						themeCopy[key].toUpperCase(),
						" key",
						key
					);
					return false;
				} else if (themeOrig[key] !== themeCopy[key]) {
					console.warn(
						"it failed on > values ",
						themeOrig[key].toUpperCase(),
						" <> ",
						themeCopy[key].toUpperCase(),
						" key",
						key
					);
					return false;
				}
			}
			return true;
		},

		equalObjects(o1, o2) {
			if (Object.keys(o1).length != Object.keys(o2).length) {
				return false;
			}

			for (let key in o1) {
				if (!Object.keys(o2).includes(key)) {
					return false;
				}

				if (typeof o1[key] !== typeof o2[key]) {
					return false;
				}
				switch (typeof o1[key]) {
					case "object": {
						if (!this.equalObjects(o1[key], o2[key])) {
							return false;
						}
						break;
					}
					default: {
						if (o1[key] !== o2[key]) {
							return false;
						}
					}
				}
			}
			return true;
		},
	},

	watch: {
		schemeToEdit(newVa, oldVa) {
			this.currentScheme = JSON.parse(JSON.stringify(this.schemeToEdit));
			this.fillColorPoints(newVa);
			if (this.$refs.textForm) {
				this.$refs.textForm.validate();
			}
		},

		schemeName(newVal, oldVal) {},
	},

	async created() {
		window.addEventListener("resize", this.changeShit);
	},

	mounted() {
		this.changeShit();
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.changeShit);
	},
};
</script>

<style></style>
