<template>
	<v-row>
		<v-col class="ma-0 pa-0">
			<v-col>
				<gradient-select
					:key="name"
					ref="selector"
					@selection-change="setPickerFocus"
					@gradient-change="onGradientChange"
					:selection-color="nodeColor"
					:current-focus="currentFocus"
					:gradient="currentGradient" />
			</v-col>
			<v-col
				cols="4"
				class="ma-auto my-0 py-0">
				<v-text-field
					class="centered"
					:disabled="
						currentFocusId === null ||
						currentFocusId === undefined ||
						wrongInput
					"
					@input="handleUserBS"
					:value="getFocusedPosition"
					ref="fild"
					:min="0"
					:max="100"
					:rules="[
						(v) => {
							return v < 101 && v > -1;
						},
					]"
					type="number"></v-text-field>
			</v-col>
			<v-color-picker
				:canvasHeight="canvasHeight"
				class="mx-auto"
				v-model="pickColor"
				:width="width"></v-color-picker>
		</v-col>
	</v-row>
</template>

<script>
import GradientSelect from "./GradientSelect.vue";

export default {
	components: {
		GradientSelect,
	},
	props: {
		name: {
			type: String,
		},
		gradientToEdit: {
			type: Object,
		},
		width: {
			type: String,
		},
		canvasHeight: {
			type: String,
			default: "250px",
		},
	},
	data: function () {
		return {
			wrongInput: false,
			currentColor: "",
			currentFocus: null,
			currentFocusId: null,
			nodeColor: "",
			editGradient: {
				colors: ["#ff0000", "#00ff00", "#0000ff"],
				stops: [0, 50.153, 100],
			},
		};
	},
	computed: {
		getFocusedPosition: {
			get() {
				const res = Math.round(this.editGradient.stops[this.currentFocusId]);
				return !isNaN(res) ? res : "";
			},
			set(v) {
				if (v < 0) v = 0;
				if (v > 100) v = 100;
				this.setGradientFromOutside(v / 100);
			},
		},
		pickColor: {
			get() {
				if (this.nodeColor) {
					return this.nodeColor;
				}
				return "#ffffff";
			},
			set(v) {
				this.nodeColor = v;
			},
		},
		currentGradient: {
			get() {
				let convertedGradient = [];
				this.editGradient.colors.forEach((val, i) => {
					convertedGradient.push({
						color: val,
						position: this.editGradient.stops[i] / 100,
					});
				});
				return convertedGradient;
			},
			set(value) {
				let colors = [];
				let stops = [];
				value.forEach((val) => {
					colors.push(val.color);
					stops.push(val.position * 100);
				});
				this.editGradient = { colors: colors, stops: stops };
			},
		},
	},
	methods: {
		setPickerFocus: function (e) {
			this.currentFocus = e.element;
			this.currentFocusId = e.index;
			this.nodeColor = e.color;
		},

		async handleUserBS(e) {
			let res = parseInt(e);
			if (isNaN(res)) res = 0;
			if (res < 0) res = 0;
			if (res > 100) res = 100;
			this.getFocusedPosition = 0;
			await this.$nextTick();
			this.getFocusedPosition = res.toString();
		},

		setGradientFromOutside(gradient) {
			this.$refs.selector.setDatas(gradient);
		},

		onGradientChange: function (e) {
			this.currentGradient = e.colors;
			this.$emit(
				"onGradientSubmit",
				this.sortColors(e.colors),
				this.currentFocusId
			);
		},

		sortColors(colors) {
			if (colors.length > 0) {
				let tmpGradient = {};
				tmpGradient[this.name + "Colors"] = [];
				tmpGradient[this.name + "ColorStops"] = [];

				let tmpCurrent = colors
					.sort((a, b) => (a.position > b.position ? 1 : -1))
					.filter((val) => val.position >= 0);
				tmpCurrent.forEach((val) => {
					tmpGradient[this.name + "Colors"].push(val.color);
					tmpGradient[this.name + "ColorStops"].push(val.position * 100);
				});

				return tmpGradient;
			}
		},

		convertToSelector() {
			let c = this.gradientToEdit[this.name + "Colors"].length;
			let s = this.gradientToEdit[this.name + "ColorStops"].length;
			if (c > 0 && s > 0) {
				this.editGradient.colors = [
					...this.gradientToEdit[this.name + "Colors"],
				];
				this.editGradient.stops = [
					...this.gradientToEdit[this.name + "ColorStops"],
				];
			}
		},
	},

	created() {
		this.convertToSelector();
	},
};
</script>

<style>
.centered {
	text-align: center !important;
}
</style>
